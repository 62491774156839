import {ChangeDetectionStrategy, Component, inject, OnDestroy} from '@angular/core';
import {DialogRef} from '@angular/cdk/dialog';
import {ButtonComponent} from '../../ui/button/button.component';

@Component({
  selector: 'app-update-available-dialog',
  templateUrl: './update-available-dialog.component.html',
  styleUrls: ['./update-available-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent]
})
export class UpdateAvailableDialogComponent implements OnDestroy {
  private dialogRef = inject<DialogRef<UpdateAvailableDialogComponent>>(DialogRef);
  private updateTimeout = setTimeout(() => this.update(), 10000);

  ngOnDestroy() {
    clearTimeout(this.updateTimeout);
  }

  update() {
    this.dialogRef.close();
    document.location.reload();
  }
}
