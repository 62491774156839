import {ChangeDetectionStrategy, Component, HostListener, inject} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, Router, RouterOutlet} from '@angular/router';
import {combineLatestWith, delay, filter, take, tap} from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import {DOCUMENT} from '@angular/common';
import {LoggerService} from './logger.service';
import {AppService} from './app.service';
import {from} from 'rxjs';
import {UpdateService} from './update/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class AppComponent {
  private readonly logger = inject(LoggerService).create('App');
  private readonly document = inject(DOCUMENT);
  private readonly title = inject(Title);
  private readonly gtmService = inject(GoogleTagManagerService);
  private readonly router = inject(Router);
  private readonly appService = inject(AppService);
  private readonly updateService = inject(UpdateService);

  navEnd$ = this.router.events.pipe(
    filter(
      (e) =>
        e instanceof NavigationEnd ||
        e instanceof NavigationCancel ||
        e instanceof NavigationError ||
        e instanceof NavigationSkipped
    ),
    take(1),
    delay(100),
  );

  ready$ = this.checkAndUpdate().pipe(
    combineLatestWith(this.navEnd$),
    filter(([update]) => !update),
  );

  constructor() {
    this.logger.logInfo('App has been started', {appId: this.appService.appId});

    this.title.setTitle(environment.title);

    Sentry.setTags({appId: this.appService.appId});

    this.ready$.subscribe(() => this.hideLoader());
    setTimeout(() => this.hideLoader(), 15000);

    this.checkAndUpdate();

    if (environment.production) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          this.gtmService.pushTag({
            event: 'page',
            pageName: item.url
          });
        }
      });
    }
  }

  @HostListener('document:visibilitychange')
  visibilitychange() {
    this.mute();
    setTimeout(() => this.mute(), 100);
    setTimeout(() => this.mute(), 500);
  }

  checkAndUpdate() {
    return from(this.updateService.checkForUpdate()).pipe(
      tap(result => {
        if (result) {
          this.showUpdatingLoader();
          this.updateService.activateUpdate();
        } else {
          this.updateService.startUpdateChecking();
        }
      }),
      take(1),
    );
  }

  private get loader() {
    return this.document.getElementById('main-loader');
  }

  private showUpdatingLoader() {
    this.loader.classList.add('updating');
  }

  private hideLoader() {
    this.loader.classList.add('hidden');
  }

  private mute() {
    this.document.querySelectorAll<HTMLMediaElement>('audio, video')
      .forEach(e => e.muted = this.document.visibilityState === 'hidden');
  }
}
