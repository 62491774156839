import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IconsModule} from '../../ui/icons/icons.module';

@Component({
  selector: 'app-connection-alert',
  templateUrl: './connection-alert.component.html',
  styleUrls: ['./connection-alert.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconsModule],
})
export class ConnectionAlertComponent {

}
