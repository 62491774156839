import {enableProdMode, ErrorHandler, importProvidersFrom, inject, isDevMode, LOCALE_ID, provideAppInitializer} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {ConnectionModule} from './app/connection/connection.module';
import {provideServiceWorker} from '@angular/service-worker';
import {MatDialogModule} from '@angular/material/dialog';
import {provideGoogleTagManager} from 'angular-google-tag-manager';
import {GraphQLModule} from './app/graphql/graphql.module';
import {withNgxsStoragePlugin} from '@ngxs/storage-plugin';
import {provideStore} from '@ngxs/store';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {ERROR_HANDLERS, ErrorHandlerManager} from './app/error-handler-manager';
import {provideRouter, Router, withRouterConfig} from '@angular/router';
import {provideLumberjackConsoleDriver} from '@ngworker/lumberjack/console-driver';
import {provideLumberjack} from '@ngworker/lumberjack';
import * as Sentry from '@sentry/angular';
import {TraceService} from '@sentry/angular';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {routes} from './app/app.routes';
import {provideCacheableAnimationLoader, provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web';
import {withNgxsReduxDevtoolsPlugin} from '@ngxs/devtools-plugin';
import {appData} from '../ngsw-config.json';

declare global {
  interface Window {
    appId: string;
    sentryTrace: string;
    sentryBaggage: string;
  }
}

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeRu);

if (environment.sentryUrl) {
  const sentryClient = Sentry.init({
    dsn: environment.sentryUrl,
    environment: environment.sentryEnv,
    release: appData.version,
    normalizeDepth: 10,
    tracesSampleRate: environment.sentrySampleRate,
    tracePropagationTargets: [environment.apiUrl],
    integrations: [
      Sentry.browserTracingIntegration({
        instrumentPageLoad: false,
      }),
      Sentry.breadcrumbsIntegration({
        console: true,
        xhr: false,
        fetch: false,
      }),
    ],
  });

  Sentry.startBrowserTracingPageLoadSpan(
    sentryClient,
    {
      name: window.location.pathname,
    },
    {
      sentryTrace: window.sentryTrace,
      baggage: window.sentryBaggage
    },
  );
}

class ChunkErrorRefreshHandler implements ErrorHandler {
  handleError(error: any): void {
    if (/Failed to fetch dynamically imported module/.test(error?.message)) {
      window.location.reload();
    }
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes, withRouterConfig({
      paramsInheritanceStrategy: 'always'
    })),
    provideStore([],
      {
        developmentMode: !environment.production
      },
      withNgxsStoragePlugin({keys: []}),
      withNgxsReduxDevtoolsPlugin({
        disabled: environment.production
      }),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately'
    }),
    importProvidersFrom(
      BrowserModule,
      GraphQLModule,
      MatDialogModule,
      ConnectionModule,
    ),
    provideLumberjack(),
    provideLumberjackConsoleDriver(),
    provideGoogleTagManager({
      id: environment.gtmId,
    }),
    provideLottieOptions({
      player: () => player,
    }),
    provideCacheableAnimationLoader(),
    {
      provide: 'googleTagManagerMode',
      useValue: 'silent'
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    {
      provide: ERROR_HANDLERS,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
      multi: true,
    },
    {
      provide: ERROR_HANDLERS,
      useClass: ChunkErrorRefreshHandler,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useExisting: ErrorHandlerManager,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
  .catch(err => console.error(err));
