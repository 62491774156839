import {inject, NgModule, provideAppInitializer} from '@angular/core';
import {CommonModule} from '@angular/common';
import {provideStates} from '@ngxs/store';
import {AuthState} from './auth.state';
import {AuthService} from './auth.service';
import {withStorageFeature} from '@ngxs/storage-plugin';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    provideAppInitializer(() => inject(AuthService).init()),
    provideStates([AuthState], withStorageFeature([AuthState])),
  ],
})
export class AuthModule {
}
