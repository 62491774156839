import {inject, Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {timer} from 'rxjs';
import {SwUpdate} from '@angular/service-worker';
import {UpdateAvailableDialogComponent} from './update-available-dialog/update-available-dialog.component';
import {Dialog} from '@angular/cdk/dialog';
import {AppService, ClientType} from '../app.service';
import semverGt from 'semver/functions/gt';
import {DOCUMENT} from '@angular/common';
import {LoggerService} from '../logger.service';

export interface AppData {
  version: string;
  urgent: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private readonly logger = inject(LoggerService).create('Update');
  private readonly document = inject(DOCUMENT);
  private readonly updates = inject(SwUpdate);
  private readonly dialog = inject(Dialog);
  private readonly appService = inject(AppService);

  async checkForUpdate() {
    return this.updates.isEnabled && await this.updates.checkForUpdate();
  }

  async activateUpdate() {
    this.logger.logInfo('Activating update');
    await this.updates.activateUpdate();
    this.reload();
  }

  startUpdateChecking() {
    if (this.updates.isEnabled) {
      this.logger.logInfo('Checking for updates');

      timer(0, 60 * 1000)
        .subscribe(() => this.updates.checkForUpdate());

      this.updates.versionUpdates
        .pipe(
          filter(event => event.type === 'VERSION_READY'),
        )
        .subscribe(event => {
          if (event.type === 'VERSION_READY') {
            const available = event.latestVersion.appData as AppData;
            const current = event.currentVersion.appData as AppData;

            this.logger.logInfo('New version installation', {available, current});
            this.updates.activateUpdate();
            if (semverGt(available.version, current.version) && available.urgent) {
              this.update();
            }
          }
        });

      this.updates.unrecoverable.subscribe((event) => {
        this.logger.logError('Unrecoverable error', {event});
      });
    }
  }

  private update() {
    const clientType = this.appService.clientType;
    this.logger.logInfo('Urgent update launched', {clientType});
    switch (clientType) {
      case ClientType.Host:
        this.showNotifyDialog();
        break;
      case ClientType.Player:
        this.reload();
    }
  }

  private showNotifyDialog() {
    this.dialog.open(UpdateAvailableDialogComponent);
  }

  private reload() {
    this.document.location.reload();
  }
}
