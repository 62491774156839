import {Routes} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {GameGuard} from './game.guard';

export const routes: Routes = [
  {
    path: '',
    children: [],
    canActivate: [GameGuard],
  },
  {
    path: 'player',
    loadChildren: () => import('./player/player.routes').then(m => m.routes),
  },
  {
    path: 'host',
    loadChildren: () => import('./host/host.routes').then(m => m.routes),
  },
  {
    path: 'preview',
    loadChildren: () => import('./preview/preview.routes').then(m => m.routes),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.routes').then(m => m.routes),
  },
  {
    path: 'catalog',
    loadChildren: () => import('./catalog/catalog.routes').then(m => m.routes),
  },
  {
    path: 'ios-app-required',
    loadComponent: () => import('./app-required/app-required-ios/app-required-ios.component')
      .then(c => c.AppRequiredIosComponent),
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];
