import {inject, NgModule, provideAppInitializer} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OfflineNotifierService} from './offline-notifier.service';
import {ConnectionAlertComponent} from './connection-alert/connection-alert.component';
import {IconsModule} from '../ui/icons/icons.module';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    ConnectionAlertComponent
  ],
  providers: [
    provideAppInitializer(() => inject(OfflineNotifierService).watchConnection()),
  ]
})
export class ConnectionModule {
}
